
.outboundProductsList {
	.text-asignado {
		margin-top: -2px;
		font-size: 12px;
	}

	.ant-table-row-cell-break-word {
		padding: 5px 10px !important;
	}

	.rowDanger {
		background-color: #f5c2c7;
	}
}
